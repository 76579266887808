<template>
  <div>
    <van-swipe :autoplay="5000" indicator-color="white" class="adv">
      <!-- <van-swipe-item><img :src="require('@/assets/home/banner.png')" /></van-swipe-item> -->
      <van-swipe-item><img :src="require('@/assets/home/banner1.jpg')" /></van-swipe-item>
      <!-- <van-swipe-item><img :src="require('@/assets/adv1.jpg')" /></van-swipe-item>-->
    </van-swipe>
    <van-image :src="require('@/assets/home/btn_sx.png')" style="position: fixed; top: 10px;width: 35px;z-index: 9"
      @click="getMine" />
    <div style="background: #fff; border-radius: 15px; margin: 10px; padding: 10px 15px 5px 15px">
      <div style="line-height: 20px; font-size: 18px;  font-weight: bold">
        <van-image :src="require('@/assets/home/bttb_kjyy.png')" style="vertical-align: middle; width: 35px;" />
        快捷应用
      </div>
      <van-grid :column-num="4" :border="false">
        <!-- <van-grid-item to="/mat/valid/new?cat=100">
          <van-icon slot="icon" size="30" color="#33cc66" name="balance-o" />
          <span slot="text" color="#33cc66" style="font-size: 14px; ">采购申请</span>
        </van-grid-item>
        <van-grid-item to="/mat/valid/new?cat=200">
          <van-icon slot="icon" size="30" color="#3aa8ec" name="shopping-cart-o" />
          <span slot="text" color="#3aa8ec" style="font-size: 14px; ">领用申请</span>
        </van-grid-item> -->
        <van-grid-item :to="item.URL" v-for="item in top_menus" :key="item.ID">
          <van-icon slot="icon" size="30" color="#3aa8ec" :name="item.ICON" />
          <span slot="text" style="font-size: 14px;color:#355661">{{item.NAME}}</span>
        </van-grid-item>

        <!-- <van-grid-item>
          <van-icon slot="icon" size="30" color="#3aa8ec" name="replay" />
          <span slot="text" style="font-size: 14px;color: #3aa8ec; ">刷新</span>
        </van-grid-item> -->
      </van-grid>
    </div>
    <div style="background: #fff; border-radius: 15px; margin: 10px; padding: 15px">
      <div style="line-height: 35px; font-size: 18px;  font-weight: bold">
        <van-image :src="require('@/assets/home/bttb_fw.png')" style="vertical-align: middle; width: 35px;" />
        服务功能
      </div>
      <van-grid :column-num="3" :border="false">
        <van-grid-item @click="goPage(item)" v-for="item in home_menus" :key="item.ID">
          <van-image slot="icon" :src="require('@/assets/home/'+item.ICON+'')" class="grid_icon" />
          <span slot="text">{{item.NAME}}</span>
        </van-grid-item>
        <!--:to="{path:'/index',query:{id:item.ID}}"-->
        <!-- <van-grid-item to="/fix">
          <van-image slot="icon" :src="require('@/assets/home/gntb_ynwx.png')" class="grid_icon" />
          <span slot="text">园内维修</span>
        </van-grid-item>
        <van-grid-item to="/mat" v-if="role.mat">
          <van-image slot="icon" :src="require('@/assets/home/gntb_wlgl.png')" class="grid_icon" />
          <span slot="text">仓库管理</span>
        </van-grid-item>
        <van-grid-item to="/mat/valid" v-if="role.mat_valid">
          <van-image slot="icon" :src="require('@/assets/home/gntb_djsp.png')" class="grid_icon" />
          <span slot="text">单据审批</span>
        </van-grid-item>
        <van-grid-item to="/safe" v-if="role.safe">
          <van-image slot="icon" :src="require('@/assets/home/gntb_aqxj.png')" class="grid_icon" />
          <span slot="text">安全巡检</span>
        </van-grid-item>
        <van-grid-item to="/rent" v-if="role.rent">
          <van-image slot="icon" :src="require('@/assets/home/gntb_wpjy.png')" class="grid_icon" />
          <span slot="text">物品借用</span>
        </van-grid-item>
        <van-grid-item to="/meet" v-if="role.meet">
          <van-image slot="icon" :src="require('@/assets/home/gntb_hygl.png')" class="grid_icon" />
          <span slot="text">会议记录</span>
        </van-grid-item>
        <van-grid-item to="/cap" v-if="role.cap">
          <van-image slot="icon" :src="require('@/assets/home/gntb_gdzc.png')" class="grid_icon" />
          <span slot="text">固定资产</span>
        </van-grid-item>
        <van-grid-item to="/func" v-if="role.func">
          <van-image slot="icon" :src="require('@/assets/home/gntb_gns.png')" class="grid_icon" />
          <span slot="text">功能室</span>
        </van-grid-item>
        <van-grid-item to="/org/teacher">
          <van-image slot="icon" :src="require('@/assets/home/gntb_grzx.png')" class="grid_icon" />
          <span slot="text">个人中心</span>
        </van-grid-item>
        <van-grid-item to="/visitor/index" v-if="role.visitor">
          <van-image slot="icon" :src="require('@/assets/home/gntb_fkdj.png')" class="grid_icon" />
          <span slot="text">访问登记</span>
        </van-grid-item>
        <van-grid-item to="/check">
          <van-image slot="icon" :src="require('@/assets/home/gntb_jskh.png')" class="grid_icon" />
          <span slot="text">教师考核</span>
        </van-grid-item>
        <van-grid-item to="/org/teahonor">
          <van-image slot="icon" :src="require('@/assets/home/gntb_jsry.png')" class="grid_icon" />
          <span slot="text">我的荣誉</span>
        </van-grid-item>
        <van-grid-item to="/ins/index">
          <van-image slot="icon" :src="require('@/assets/home/gntb_jcxj.png')" class="grid_icon" />
          <span slot="text">检查巡检</span>
        </van-grid-item>
        <van-grid-item to="/scrap/index">
          <van-image slot="icon" :src="require('@/assets/home/gntb_wzbf.png')" class="grid_icon" />
          <span slot="text">物资报废</span>
        </van-grid-item> -->
      </van-grid>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        role: {
          mat: false,
          mat_valid: false,
          cap: false,
          safe: false,
        },
        //model: { SCHOOL: {} },
        all_menus: [],
        home_menus: [],
        top_menus: []
      }
    },
    created() {
      var user = this.whale.user.get()
      if (!user)
        this.getMine()
      else {
        this.all_menus = user.REL_M_MENU;
        this.home_menus = user.REL_M_MENU.filter(x => x.GROUP == 'HOME');
        this.top_menus = user.REL_M_MENU.filter(x => x.IS_TOP);
      }

      let to = this.$route.query.to;
      if (to != undefined) {
        to += "&back=home";
        this.$router.push(to);
      }
    },
    methods: {
      getMine() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/Mobile/ORG/TeacherApi/Mine",
          completed(m) {
            // self.model = m.DATA;
            self.whale.user.set(m.DATA);
            self.all_menus = m.DATA.REL_M_MENU;
            self.home_menus = m.DATA.REL_M_MENU.filter(x => x.GROUP == 'HOME');
            self.top_menus = m.DATA.REL_M_MENU.filter(x => x.IS_TOP);
            // self.role = {
            //   mat: self.whale.user.validate('/mat'),
            //   mat_valid: self.whale.user.validate('/mat/valid'),
            //   cap: self.whale.user.validate('/cap'),
            //   safe: self.whale.user.validate('/safe'),
            //   rent: self.whale.user.validate('/rent'),
            //   visitor: self.whale.user.validate('/visitor'),
            //   func: self.whale.user.validate('/func'),
            //   meet: self.whale.user.validate('/meet'),
            //   check: self.whale.user.validate('/check'),
            //   perform: self.whale.user.validate('/perform'),
            // }
          }
        })
      },
      goPage(item) {
        var children = this.all_menus.filter(x => x.PARENT_ID == item.ID)
        if (children.length > 0)
          this.$router.push({ path: '/index', query: { id: item.ID } })
        else
          this.$router.push(item.URL)
      }
    }
  }
</script>
<!--<style lang="less">-->
<!--  @van-grid-item__text: { fontSize: 16px}-->
<!--</style>-->
<style scoped>
  .adv {
    width: 100%;
    min-height: 200px
  }

  .adv img {
    max-width: 100%;
  }

  .van-grid-item__text {
    font-size: 16px
  }

  .van-image__img {}

  .grid_icon {
    width: 70px
  }
</style>